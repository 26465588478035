/*import uid from 'uid'*/

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
const oneLink = (tokenId, cardId, update = 0) => ({ cardId, tokenId, update })*/

const oneCard = (tokenId, cardId, date = 0, theme) => {
  let title = ''
  let parts = [{ type: '' }]
  return {
    cardId,
    createdTime: date,
    parts,
    title,
    tokenId,
    theme,
    updateTime: date,
  }
}

export const createCards = (tokenCardLinks, date, theme) => {
  const list = [];

  Object.values(tokenCardLinks).map((link) => list.push(oneCard(link.tokenId, link.cardId, date, theme)))

  return list
}

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
// old contact page had 'c' as the tokenId
const getTokenCardLinks = (tokenList, cardList, date) => {
  const list = {}
  Object.values(tokenList).map((tokenId, i) =>
    tokenId === 'c'
      ? Object.assign(list, { 999999: oneLink(tokenId, '999999', date) })
      : Object.assign(list, { [i]: oneLink(tokenId, cardList[i], date) })
  )
  return list
}*/

export const getTokenCardLinks = (tokenList, date) => {
  const list = {}

  tokenList.map((token, index) => {
    return list[index] = {cardId: token.toString(), tokenId: parseInt(token), update: date}
  })

  return list
}

const getListThemes = (themes) => {
  const list = []

  themes.map((theme) => list.push(theme.value))

  return list
}

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
const getCardList = (cards) => {
  const list = []
  for (let i = 1; i <= cards; i += 1) {
    list.push(`${i}`)
  }
  return list
}*/

const getTokenList = (tokens) => {
  const list = []
  for (let i = 1; i <= tokens; i += 1) {
    list.push(i)
  }
  return list
}

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
const getCards = (date, dbCards, tokens, dbTokens) => {
  const tokensDiff = tokens - dbTokens

  if (tokensDiff === 0) {
    return dbCards
  }

  const cardsCopy = { ...dbCards }

  if (tokensDiff < 0) {
    for (let i = 0; i < tokensDiff * -1; i += 1) {
      const id = uid()
      const index = dbTokens - i

      delete Object.assign(cardsCopy, { [id]: cardsCopy[index] })[index]

      cardsCopy[id] = {
        ...cardsCopy[id],
        ...{ cardId: id, tokenId: 0 },
      }
    }
  } else {
    for (let i = 1; i <= tokensDiff; i += 1) {
      const key = dbTokens + i
      const card = oneCard(key, String(key), date)

      Object.assign(cardsCopy, { [key]: card })
    }
  }

  return cardsCopy
}*/

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
export const getApp = (app, dbCards, dbApp) => {
  const date = Date.now()

  // filter because of old contact page that had id 999999
  const dbTokens = Object.values(dbCards).filter(
    (card) => !isNaN(card.cardId) && card.cardId !== '999999'
  ).length
  const tokens = parseInt(app.numberTokens, 10)

  const tokenList = getTokenList(tokens)
  const cardList = getCardList(tokens)

  const tokenCardLinks = getTokenCardLinks(tokenList, cardList, date)
  const legalContacts = app.legalContacts

  // create the app object
  const appObject = {
    id: app.id,
    name: app.name,
    theme: app.theme || '',
    themes: app.themes || [],
    notes: app.notes || '',
    lastCardUpdateTime: date,
    tracking: {
      ga4code: (app.tracking && app.tracking.ga4code) || '',
    },
    cardList,
    tokenList,
    tokenCardLinks,
    GDPR: { ...app.GDPR },
  }

  // create the cards object
  let newCards = getCards(date, dbCards, tokens, dbTokens)

  // rewrite parameter in app object
  const newCardIds = []
  Object.values(newCards).map((card) => Object.assign(newCardIds, { [card.cardId]: card.cardId }))
  appObject.cardList = newCardIds.filter(String)

  const cardsToUpdate = [];
  const updateCards = Object.values(newCards).map((card) => {
    if(app.changeDefaultTheme && (card.theme === app.oldTheme || card.theme === '')) {
      const cardUpdated = { ...card, theme: app.theme }
        cardsToUpdate.push(cardUpdated)
      return cardUpdated
    }
    return card;
  })
  delete app.oldTheme

  appObject.tokenCardLinks = getTokenCardLinks(appObject.tokenList, appObject.cardList, date)

  return {
    app: appObject,
    cards: updateCards,
    legalContacts: legalContacts,
    cardsToUpdate: cardsToUpdate
  }
}*/
export const getApp = (dbApp, app, date) => {
  const tokens = parseInt(app.numberTokens, 10)

  const tokenList = getTokenList(tokens)
  const themes = getListThemes(app.themes)

  return {
      ...dbApp,
      name: app.name,
      theme: app.theme,
      themes: themes,
      notes: app.notes,
      lastCardUpdateTime: date,
      tracking: {
        ga4code: (app.tracking && app.tracking.ga4code),
      },
      legalContacts: app.legalContacts,
      internalNotes: app.internalNotes,
      GDPR: app.GDPR,
      plan: app.plan,
      tokenList,
  }

  /*const date = Date.now()

  // filter because of old contact page that had id 999999
  const dbTokens = Object.values(dbCards).filter(
    (card) => !isNaN(card.cardId) && card.cardId !== '999999'
  ).length
  const tokens = parseInt(app.numberTokens, 10)

  const tokenList = getTokenList(tokens)
  const cardList = getCardList(tokens)

  const tokenCardLinks = getTokenCardLinks(tokenList, cardList, date)
  const legalContacts = app.legalContacts

  // create the app object
  const appObject = {
    id: app.id,
    name: app.name,
    theme: app.theme || '',
    themes: app.themes || [],
    notes: app.notes || '',
    lastCardUpdateTime: date,
    tracking: {
      ga4code: (app.tracking && app.tracking.ga4code) || '',
    },
    cardList,
    tokenList,
    tokenCardLinks,
    GDPR: { ...app.GDPR },
  }

  // create the cards object
  let newCards = getCards(date, dbCards, tokens, dbTokens)

  // rewrite parameter in app object
  const newCardIds = []
  Object.values(newCards).map((card) => Object.assign(newCardIds, { [card.cardId]: card.cardId }))
  appObject.cardList = newCardIds.filter(String)

  // remove themes from cards if themes changed
  Object.values(newCards).forEach((card) => {
    if(app.changeDefaultTheme && app.theme !== dbApp.theme && (card.theme===dbApp.theme || card.theme === '')){
      const c = { ...card, theme: app.theme }
      Object.assign(newCards, { [card.cardId]: c })
    }
    if (app.themes.indexOf(card.theme) === -1) {
      const c = { ...card, theme: '' }
      Object.assign(newCards, { [card.cardId]: c })
    }
  })

  const newTokenCards = getTokenCardLinks(appObject.tokenList, appObject.cardList, date)
  appObject.tokenCardLinks = newTokenCards

  return {
    app: appObject,
    cards: newCards,
    legalContacts: legalContacts,
  }
  /!*const cardsToUpdate = [];
  const updateCards = Object.values(newCards).map((card) => {
    if(app.changeDefaultTheme && (card.theme === app.oldTheme || card.theme === '')) {
      const cardUpdated = { ...card, theme: app.theme }
        cardsToUpdate.push(cardUpdated)
      return cardUpdated
    }
    return card;
  })
  delete app.oldTheme

  appObject.tokenCardLinks = getTokenCardLinks(appObject.tokenList, appObject.cardList, date)

  return {
    app: appObject,
    cards: updateCards,
    legalContacts: legalContacts,
    cardsToUpdate: cardsToUpdate
  }*!/*/
}

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
export const getNewApp = (app) => {
  const tokens = parseInt(app.numberTokens, 10)
  const date = Date.now()

  const tokenList = getTokenList(tokens)
  const cardList = getCardList(tokens)
  const tokenCardLinks = getTokenCardLinks(tokenList, cardList, date)
  const legalContacts = app.legalContacts

  // create the app object
  const appObject = {
    id: app.id,
    name: app.name,
    theme: app.theme || '',
    themes: app.themes || [],
    notes: app.notes || '',
    lastCardUpdateTime: date,
    tracking: {
      ga4code: (app.tracking && app.tracking.ga4code) || '',
    },
    cardList,
    tokenList,
    tokenCardLinks,
    legalContacts,
  }

  // create the cards object
  const newCards = createCards(tokenCardLinks, date)

  return {
    app: appObject,
    cards: newCards,
    legalContacts: legalContacts,
  }
}*/
export const getNewApp = (app, date) => {
  const tokens = parseInt(app.numberTokens, 10)

  const tokenList = getTokenList(tokens)
  const tokenCardLinks = getTokenCardLinks(tokenList, date)
  const themes = getListThemes(app.themes)

  return {
    id: app.id,
    name: app.name,
    theme: app.theme || '',
    themes: themes || [],
    notes: app.notes || '',
    lastCardUpdateTime: date,
    tracking: {
      ga4code: (app.tracking && app.tracking.ga4code) || '',
    },
    legalContacts: app.legalContacts,
    internalNotes: app.internalNotes ? app.internalNotes : '',
    GDPR: app.GDPR,
    foldersOrder: 'desc',
    plan: app.plan,
    cardList: [],
    tokenCardLinks,
    tokenList
  };
}
