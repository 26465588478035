import { useEffect } from 'react'
import { FormText, Label } from 'reactstrap'
import { AvFeedback, AvGroup, AvInput } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const getHeight = (size) => {
  switch (size) {
    case 'xsmall':
      return '75px'
    case 'small':
      return '90px'
    default:
      return '180px'
  }
}

const StyledTextArea = styled(AvInput)`
  min-height: ${({ bsSize }) => getHeight(bsSize)} !important;
  border-radius: 10px !important;
  border: 1px solid #bebebe;
  padding: 14px;
  font-size: 15px;
  width: auto;

  &::placeholder {
    color: #bebebe;
  }
`

const StyledLabel = styled(Label)`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`

const StyledInput = styled(AvGroup)`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

// comes from class invalid-feedback in bootstrap file
const StyledError = styled.span`
  opacity: 1;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
`

const BasicTextArea = ({
  disabled,
  error,
  helpMessage,
  label,
  name,
  onBlur,
  onChange,
  partIndex,
  placeholder,
  readOnly,
  required,
  size,
  validation,
  validationOneOf,
  value,
}) => {
  const partName = partIndex ? `${name}-${partIndex}` : name
  const validate = validation || {}

  useEffect(() => {
    if (validationOneOf) {
      validate.async = (val, ctx, input, cb) => {
        cb(validationOneOf.includes(val))
      }
    }
  }, [validationOneOf])

  const handleChange = (e) => {
    onChange(e, partIndex)
  }

  return (
    <StyledInput>
      {!!label && (
        <StyledLabel htmlFor={partName}>
          {label}
          {required && <StyledError>&nbsp;*</StyledError>}
        </StyledLabel>
      )}

      {helpMessage && <FormText>{helpMessage}</FormText>}

      <StyledTextArea
        disabled={disabled}
        id={name}
        name={partName}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        bsSize={size}
        type="textarea"
        validate={validate}
        value={value}
      />

      {error && <AvFeedback>{error}</AvFeedback>}
    </StyledInput>
  )
}

BasicTextArea.defaultProps = {
  name: '',
  onBlur: () => {},
  onChange: () => {},
  value: '',
}

BasicTextArea.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  partIndex: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  validation: PropTypes.any,
  validationOneOf: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
}

export default BasicTextArea
