import PropTypes from 'prop-types'
import { BasicButton } from 'components'
import { ADMIN_URL } from 'utils/consts'
import styled from 'styled-components'

import { ScheduleTooltip } from '../components/Tooltips'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledText = styled.div`
  p {
    margin: 10px !important;
    line-height: 20px;
    word-break: break-all;
  }
`

const StyledYellowButton = styled.div`
  width: 100%;

  button,
  a {
    color: black;
    border-radius: 16px;
    padding: 6px 12px;
    width: 100%;
    white-space: break-spaces !important;

    &:hover {
      color: black !important;
    }
  }
`

const Button = ({ cardId, part, partIndex }) => {
  const isExternalLink = part && part.url && !part.url.includes(ADMIN_URL)

  return (
    <StyledWrapper key={`${cardId}-button-${partIndex}`}>
      <StyledYellowButton>
        {part && !!part.url ? (
          <>
            {isExternalLink ? (
              <>
                {part.tags && part.tags.beforeText && part.tags.beforeText !== '' && (<StyledText><p>{part.tags.beforeText}</p></StyledText>)}
                <BasicButton
                  color="yellow"
                  href={(part && part.url) || ''}
                  target="_blank"
                  outlined={part && part.style ? part.style.alternate : false}
                  label={part.text !== '' ? <span dangerouslySetInnerHTML={{ __html: part.text }} /> : ''}
                />
                {part.tags && part.tags.afterText && part.tags.afterText !== '' && (<StyledText><p>{part.tags.afterText}</p></StyledText>)}
              </>
            ) : (
              <>
                {part.tags && part.tags.beforeText && part.tags.beforeText !== '' && (<StyledText><p>{part.tags.beforeText}</p></StyledText>)}
                <BasicButton
                  isLink
                  color="yellow"
                  href={(part && part.url) || ''}
                  target="_blank"
                  outlined={part && part.style ? part.style.alternate : false}
                  label={part.text !== '' ? <span dangerouslySetInnerHTML={{ __html: part.text }} /> : ''}
                />
              {part.tags && part.tags.afterText && part.tags.afterText !== '' && (<StyledText><p>{part.tags.afterText}</p></StyledText>)}
            </>
            )}
          </>
        ) : (
          <>
            {part.tags && part.tags.beforeText && part.tags.beforeText !== '' && (<StyledText><p>{part.tags.beforeText}</p></StyledText>)}
            <BasicButton
              color="yellow"
              onClick={() => {}}
              outlined={part && part.style ? part.style.alternate : false}
              label={part.text !== '' ? <span dangerouslySetInnerHTML={{ __html: part.text }} /> : ''}
            />
            {part.tags && part.tags.afterText && part.tags.afterText !== '' && (<StyledText><p>{part.tags.afterText}</p></StyledText>)}
          </>
        )}
      </StyledYellowButton>

      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Button.propTypes = {
  cardId: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default Button
