import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { BasicInput, PartOptions, BasicCheckbox, BasicLabel } from 'components'
import { useIntl } from 'react-intl'
import { validationsPatterns, newEvent } from 'utils'

import StyleForm from './StyleForm'
import messages from './messages'
import TagsForm from "./TagsForm";
import BasicTextArea from '../../BasicTextArea'

const ButtonForm = ({ onChange, onCheckedChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  useEffect(() => {
    if (!part.style) {
      onChange({ target: { id: 'style', value: {} } }, partIndex)
    }
  }, [])

  const handleOnChange = (e) => onChange(e, partIndex)

  const handleValidationText = (value) => {
    return value.trim() !== '';
  }

  return (
    <>
      <PartOptions
        partIndex={partIndex}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        values={part.options}
        styleForm={<StyleForm part={part} partIndex={partIndex} onCheckedChange={(e) => onCheckedChange(e, partIndex)} onFormChange={onFormChange} />}
        tagsForm={<TagsForm part={part} onChange={(e) => handleOnChange(e, partIndex)} onCheckedChange={(e) => onCheckedChange(e, partIndex)} onFormChange={onFormChange} />}
        onFormChange={onFormChange}
      />

      <BasicTextArea
        label={intl.formatMessage(messages.text)}
        helpMessage={intl.formatMessage(messages.textHelp)}
        name="text"
        partIndex={partIndex}
        value={part.text || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="xsmall"
        error={intl.formatMessage(messages.textError)}
        validation={{
          async: (value) => handleValidationText(value),
        }}
      />

      {!part.return && (
        <BasicInput
          name="url"
          label={intl.formatMessage(messages.link)}
          helpMessage={intl.formatMessage(messages.linkHelp)}
          placeholder="https://www.google.com"
          partIndex={partIndex}
          onChange={(e) => {
            handleOnChange(e)
            onFormChange(true)
          }}
          value={part.url}
          error={intl.formatMessage(messages.linkError)}
          validation={{
            pattern: validationsPatterns.urlExtended,
          }}
        />
      )}

      <BasicCheckbox
        label={intl.formatMessage(messages.return)}
        helpMessage={intl.formatMessage(messages.returnHelp)}
        name="return"
        value={part.return || false}
        onChange={(e) => {
          onChange(newEvent('return', e.target.checked), partIndex)
          onChange(newEvent('msjBrowser', false), partIndex)
          onChange(newEvent('url', ''), partIndex)
          onFormChange(true)
        }}
      />
    </>
  )
}

ButtonForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default ButtonForm
