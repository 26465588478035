import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.ButtonForm'

export default defineMessages({
  alternate: { id: `${scope}.alternate` },
  alternateHelp: { id: `${scope}.alternateHelp` },
  link: { id: `${scope}.link` },
  linkError: { id: `${scope}.linkError` },
  linkHelp: { id: `${scope}.linkHelp` },
  msjBrowser: { id: `${scope}.msjBrowser` },
  msjBrowserHelp: { id: `${scope}.msjBrowserHelp` },
  return: { id: `${scope}.return` },
  returnHelp: { id: `${scope}.returnHelp` },
  text: { id: `${scope}.text` },
  textHelp: { id: `${scope}.textHelp` },
  textError: { id: `${scope}.textError` },
  textPlaceholder: { id: `${scope}.textPlaceholder` },
  warning: { id: `${scope}.warning` },
  tagsInfo: { id: `${scope}.tagsInfo` },
  tagsBeforeLabel: { id: `${scope}.tagsBeforeLabel` },
  tagsBeforeHelp: { id: `${scope}.tagsBeforeHelp` },
  tagsAfterLabel: { id: `${scope}.tagsAfterLabel` },
  tagsAfterHelp: { id: `${scope}.tagsAfterHelp` }
})
