import firebase from 'firebase'
import 'firebase/firestore';
import ReduxSagaFirebase from 'redux-saga-firebase'

var env = process.env.REACT_APP_HOST_TYPE
const mode = require(`./configs/${env}.json`);

firebase.initializeApp(mode)
firebase.firestore().settings({})

const myFirebaseApp = firebase
const rsFirebase = new ReduxSagaFirebase(myFirebaseApp)

export const firebaseApp = myFirebaseApp
export const db = firebase.firestore();
export default rsFirebase

